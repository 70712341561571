import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * For everyone Users
 */
export const GooglePaymentsAPI = {
    getReadyCheckObject: async function() {
        return await BaseAPI.get('/google-payment/ready-check-object')
    },
    getPaymentRequestObject: async function() {
        return await BaseAPI.get('/google-payment/payment-request-object')
    }
}
