export const messages = {
    en: {
        // ...
        updatedAt: 'Updated {date}'
    },
    fr: {
        // ...
        updatedAt: 'Updated {date}'
    }
}
