import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "footer-stick-button" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_game_box = _resolveComponent("game-box")!
  const _component_swiper_box = _resolveComponent("swiper-box")!
  const _component_router_localization_link = _resolveComponent("router-localization-link")!
  const _component_text_box = _resolveComponent("text-box")!
  const _component_debug_buttons = _resolveComponent("debug-buttons")!
  const _component_page_footer = _resolveComponent("page-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_header),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_game_box),
            _createVNode(_component_swiper_box, {
              galleryID: "game-gallery",
              images: $data.images
            }, null, 8, ["images"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_router_localization_link, {
                to: "select-game-difficulty",
                class: "btn btn-primary btn-primary-next mt-3 mb-3 p-13px",
                onClick: $options.playNowClickHandler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" PLAY NOW ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_text_box, {
              header: _ctx.$t('aboutThisGameHead'),
              class: "main-page-text-box"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "mb-4",
                  innerHTML: _ctx.$t('aboutThisGame')
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_debug_buttons, { visible: false })
          ])
        ])
      ])
    ]),
    _createVNode(_component_page_footer)
  ], 64))
}