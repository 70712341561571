declare const localStorage: any

declare const debug: any

export const AppLocalStorage = {
    clear: function() {
        localStorage.clear()
        alert('Storage Cleared Success')
    },

    setUserToken: function(userToken: string) {
        localStorage.setItem('userToken', userToken)
        debug.log('setUserToken', userToken)
    },
    getUserToken: function() {
        const userToken = localStorage.getItem('userToken')
        debug.log('getUserToken', userToken)

        return userToken
    },
    isUserTokenExists: function(): boolean {
        const userToken = this.getUserToken()
        const userTokenExists = (userToken != null) ? userToken.length > 0 : false
        debug.log('userTokenExists', userTokenExists)

        return userTokenExists
    },

    setSubscriberFingerprint: function(subscriberFingerprint: string) {
        localStorage.setItem('subscriberFingerprint', subscriberFingerprint)
        debug.log('setSubscriberFingerprint', subscriberFingerprint)
    },
    getSubscriberFingerprint: function() {
        const subscriberFingerprint = localStorage.getItem('subscriberFingerprint')
        debug.log('getSubscriberFingerprint', subscriberFingerprint)

        return subscriberFingerprint
    },
    isSubscriberFingerprintExists: function(): boolean {
        const subscriberFingerprint = this.getSubscriberFingerprint()
        const subscriberFingerprintExists = (subscriberFingerprint != null) ? subscriberFingerprint.length > 0 : false
        debug.log('subscriberFingerprintExists', subscriberFingerprintExists)

        return subscriberFingerprintExists
    },

    setUserEmail: function(email: string) {
        localStorage.setItem('userEmail', email)
        debug.log('setUserEmail', email)
    },
    getUserEmail: function() {
        const userEmail = localStorage.getItem('userEmail')
        debug.log('getUserEmail', userEmail)

        return userEmail
    },
    isUserEmailExists: function(): boolean {
        const userEmail = this.getUserEmail()
        const userEmailExists = (userEmail != null) ? userEmail.length > 0 : false
        debug.log('userEmailExists', userEmailExists)

        return userEmailExists
    },

    setRememberMe: function(rememberMe: string) {
        localStorage.setItem('rememberMe', rememberMe)
        debug.log('setRememberMe', rememberMe)
    },
    getRememberMe: function() {
        const rememberMe = localStorage.getItem('rememberMe')
        debug.log('getRememberMe', rememberMe)

        return rememberMe
    },

    setGameSettingsDifficulty: function(difficulty: string) {
        localStorage.setItem('gameSettingsDifficulty', difficulty)
        debug.log('setGameSettingsDifficulty', difficulty)
    },
    getGameSettingsDifficulty: function() {
        const difficulty = localStorage.getItem('gameSettingsDifficulty')
        debug.log('getGameSettingsDifficulty', difficulty)

        return difficulty
    },
    isGameSettingsDifficulty: function(): boolean {
        const difficulty = this.getGameSettingsDifficulty()
        const difficultyExists = (difficulty != null) ? difficulty.length > 0 : false
        debug.log('difficultyExists', difficultyExists)

        return difficultyExists
    },

    setABTest: function(key: string, abTest: string) {
        localStorage.setItem(`abTest.${key}`, abTest)
        debug.log(`abTest.${key}`, abTest)
    },
    getABTest: function(key: string): string {
        const abTest = localStorage.getItem(`abTest.${key}`)
        debug.log(`abTest.${key}`, abTest)

        return abTest
    },
    isABTestExists: function(key: string): boolean {
        const abTest = this.getABTest(key)
        const abTestExists = (abTest != null) ? abTest.length > 0 : false
        debug.log('abTestExists', abTestExists)

        return abTestExists
    }
}
