
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/scrollbar'
// import required modules
import {Scrollbar, Mousewheel, Keyboard} from 'swiper'

import PhotoSwipe from '../../../../../../../node_modules/photoswipe/dist/photoswipe.esm.js'
import PhotoSwipeLightbox from '../../../../../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js'
import '../../../../../../../node_modules/photoswipe/dist/photoswipe.css'

import 'lazysizes'
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

export default {
    name: 'SwiperBox',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        galleryID: String,
        images: Array
    },
    setup(props) {
        return {
            imagesData: props.images,
            modules: [Scrollbar, Mousewheel, Keyboard]
        }
    },
    async mounted() {
        if (!this.lightbox) {
            const leftArrowSVGString = await this.loadSvg(require('@/assets/img/icons/swiper/arrow-left.svg'))
            const leftCloseSVGString = await this.loadSvg(require('@/assets/img/icons/swiper/close.svg'))
            this.lightbox = new PhotoSwipeLightbox({
                arrowPrevSVG: leftArrowSVGString,
                arrowNextSVG: leftArrowSVGString,
                closeSVG: leftCloseSVGString,

                // to apply styles just to this instance of PhotoSwipe
                mainClass: 'pswp--custom-icon-colors',

                arrowPrev: true,
                arrowNext: true,
                zoom: false,
                close: true,
                counter: false,

                gallery: '#' + this.$props.galleryID,
                children: 'a',
                pswpModule: PhotoSwipe
            })
            this.lightbox.init()
        }
    },
    unmounted() {
        if (this.lightbox) {
            this.lightbox.destroy()
            this.lightbox = null
        }
    },
    methods: {
        async loadSvg(url) {
            const result = await fetch(url)
                .then(function(response) {
                    return response.text()
                })
            return result
        }
    }
}
