export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this game"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seek and find game. Search items, hidden objects, and figures. Brain teaser to improve memory, increase visual attention, focus power, motor skills. Mind booster! Simple and intuitive look alike puzzle! This is your peace and calm for the day. Give it a try! Your brilliant patience test! This is a new free hidden object game.<br />🌟 GAME HIGHLIGHTS 🌟<br /> - Find things and items. Almost the same (identical) details;<br /> - Seek up to 5 differences side by side per picture;<br /> - 1000s of joyful, beautiful HD images: animals, food, rooms, nature and more;<br /> - Help your eyes. Zoom objects to notice and detect hidden puzzles;<br /> - Use unlimited hints to spot what is different as far as you can;<br /> - 1000s of challenging compare levels and clues;<br /> - Simple, beautiful and lovely design!<br /> - Relaxing and calm, joyful, cognitive experience of the app;"])}
      },
      "fr": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de ce jeu"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherchez et trouvez du gibier. Recherchez des objets, des objets cachés et des figurines. Casse-tête pour améliorer la mémoire, augmenter l'attention visuelle, la puissance de concentration, la motricité. Booster d'esprit! Puzzle simple et intuitif qui se ressemble! C'est votre paix et votre calme pour la journée. Essayez-le! Votre brillant test de patience! Ceci est un nouveau jeu d'objets cachés gratuit.<br />🌟 HIGHLIGHTS FAITS SAILLANTS DU JEU 🌟<br /> - Trouvez des choses et des objets. Presque les mêmes détails (identiques);<br /> - Recherchez jusqu'à 5 différences côte à côte par image;<br / > - des milliers de belles images HD joyeuses: animaux, nourriture, chambres, nature et plus encore;<br /> - Aidez vos yeux. Zoomez sur les objets pour remarquer et détecter les énigmes cachées;<br /> - Utilisez des indices illimités pour repérer ce qui est différent autant que vous le pouvez;<br / > - des milliers de niveaux et d'indices difficiles à comparer;<br /> - Design simple, beau et charmant!<br /> - Expérience relaxante et calme, joyeuse et cognitive de l'application;"])}
      },
      "de": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über dieses Spiel"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel suchen und finden. Suchbegriffe, versteckte Objekte und Figuren. Denkaufgabe zur Verbesserung des Gedächtnisses, Steigerung der visuellen Aufmerksamkeit, Fokussierung der Kraft, Motorik. Geist Booster! Einfaches und intuitives Puzzle, das gleich aussieht! Das ist deine Ruhe und Gelassenheit für den Tag. Probieren Sie es aus! Ihre brillante Geduldsprobe! Dies ist ein neues kostenloses Wimmelbildspiel.<br />🌟 SPIELHIGHLIGHTS 🌟<br /> - Finde Dinge und Gegenstände. Fast die gleichen (identischen) Details;<br /> - Suche bis zu 5 Unterschiede nebeneinander pro Bild;<br /> - 1000 freudige, schöne HD-Bilder: Tiere, Essen, Räume, Natur und mehr;<br /> - Hilf deinen Augen. Zoomen Sie Objekte, um versteckte Rätsel zu erkennen und zu entdecken.<br /> - Verwenden Sie unbegrenzte Hinweise, um so weit wie möglich zu erkennen, was anders ist.<br /> - 1000 herausfordernde Vergleichsstufen und Hinweise;<br /> - Einfaches, schönes und schönes Design!<br /> - Entspannende und ruhige, freudige, kognitive Erfahrung der App;"])}
      },
      "tr": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu oyun hakkında"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunu ara ve bul. Öğeleri, gizli nesneleri ve şekilleri arayın. Hafızayı geliştirmek, görsel dikkati artırmak, odaklanma gücü, motor becerileri için zeka oyunu. Zihin güçlendirici! Basit ve sezgisel bir görünüm hem bulmaca! Bu gün için huzurunuz ve huzurunuz. Bir deneyin! Parlak sabır testin! Bu yeni bir ücretsiz gizli nesne oyunudur.<br />🌟 OYUN ÖZETİ 🌟<br /> - Eşyaları ve eşyaları bulun. Hemen hemen aynı (aynı) detaylar;<br /> - Resim başına yan yana 5 farklılığa kadar arama yapın;<br /> - 1000'lerce neşeli, güzel HD görüntü: hayvanlar, yiyecekler, odalar, doğa ve daha fazlası;<br /> - Gözlerinize yardım edin. Gizli bulmacaları fark etmek ve tespit etmek için nesneleri yakınlaştırın;<br /> - Mümkün olduğunca farklı olanı tespit etmek için sınırsız ipucu kullanın;<br /> - 1000 zorlu karşılaştırma seviyesi ve ipucu;<br /> - Basit, güzel ve hoş tasarım!<br /> - Uygulamanın rahatlatıcı ve sakin, neşeli, bilişsel deneyimi;"])}
      },
      "pl": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tej grze"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj i znajdź grę. Szukaj przedmiotów, ukrytych przedmiotów i figur. Łamigłówka poprawiająca pamięć, zwiększająca uwagę wzrokową, siłę skupienia, zdolności motoryczne. Wzmacniacz umysłu! Proste i intuicyjne wyglądają podobnie puzzle! To jest twój spokój i spokój na cały dzień. Spróbuj! Twój genialny test cierpliwości! Jest to nowa darmowa gra ukrytych przedmiotów.<br />🌟 HIGHLIGHTS GRA PODKREŚLA 🌟<br /> - Znajdź rzeczy i przedmioty. Prawie te same (identyczne) szczegóły;<br /> - Szukaj do 5 różnic obok siebie na zdjęcie;<br /> - tysiące radosnych, pięknych obrazów HD: zwierzęta, jedzenie, pokoje, przyroda i inne;<br /> - Pomóż swoim oczom. Powiększ obiekty, aby zauważyć i wykryć ukryte łamigłówki; <br /> - użyj nieograniczonej liczby wskazówek, aby dostrzec, co jest inne, o ile możesz;<br /> - 1000 trudnych poziomów porównania i wskazówek;<br /> - prosty, piękny i piękny design!<br /> - relaksujące i spokojne, radosne, poznawcze doświadczenie aplikacji;"])}
      },
      "pt": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre este jogo"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar e encontrar jogo. Pesquise itens, objetos ocultos e figuras. Quebra-cabeças para melhorar a memória, aumentar a atenção visual, poder de foco, habilidades motoras. Reforço mental! Quebra-cabeça simples e intuitivo! Esta é a sua paz e calma para o dia. Experimente! Seu teste de paciência brilhante! Este é um novo jogo de objetos escondidos gratuito.<br />🌟 DESTAQUES DO JOGO 🌟<br /> - Encontre coisas e itens. Quase os mesmos detalhes (idênticos);<br /> - procure até 5 diferenças lado a lado por imagem;<br /> - 1000s de imagens HD alegres e bonitas: animais, comida, quartos, natureza e muito mais;<br /> - Ajude seus olhos. Zoom objetos para perceber e detectar quebra-cabeças escondidos; <br /> - Use dicas ilimitadas para detectar o que é diferente, tanto quanto você pode;<br /> - 1000s de níveis de comparação desafiadoras e pistas;<br /> - Design Simples, bonito e encantador!<br /> - experiência relaxante e calma, alegre e cognitiva do aplicativo;"])}
      },
      "zn": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于这个游戏"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找和寻找游戏。 搜索项目，隐藏的对象和数字。 脑筋急转弯，以提高记忆力，增加视觉注意力，焦点力量，运动技能。 心灵助推器！ 简单直观的外观相似的难题! 这是你的和平与平静的一天。 试一试！ 你辉煌的耐心测试！ 这是一个新的免费隐藏对象游戏。<br />🌟《游戏亮点》🌟<br /> -查找事物和物品。 几乎相同（相同）的细节;<br /> -每张图片并排寻求多达5个差异;<br /> -1000s的快乐，美丽的高清图像：动物，食物，房间，自然等等;<br /> -帮助你的眼睛。 缩放对象，以注意和检测隐藏的谜题;<br /> -使用无限的提示，发现什么是不同的，你可以;<br/> -1000s的具有挑战性的比较水平和线索;<br /> -简单，美丽和可爱的设计！<br /> -放松和平静，快乐，认知体验的应用程序;"])}
      },
      "ru": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об этой игре"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ищите и находите дичь. Ищите предметы, спрятанные объекты и фигуры. Мозговой тизер для улучшения памяти, увеличения зрительного внимания, силы фокусировки, двигательных навыков. Усилитель разума! Простая и интуитивно понятная головоломка, похожая друг на друга! Это ваш мир и успокоение на весь день. Дайте ему попробовать! Ваше блестящее испытание на терпение! Это новая бесплатная игра в поиск предметов.<br /> 🌟 ОСНОВНЫЕ МОМЕНТЫ ИГРЫ 🌟<br /> - Находите предметы. Почти одинаковые детали;<br /> - Ищите до 5 отличий рядом на картинке;<br /> - 1000 радостных, красивых HD-изображений: животные, еда, комнаты, природа и многое другое;<br /> - Помогайте своим глазам. Увеличивайте масштаб объектов, чтобы замечать и обнаруживать скрытые головоломки;<br /> - Используйте неограниченное количество подсказок, чтобы определить, что отличается, насколько это возможно;<br /> - 1000 захватывающих уровней сравнения и подсказок;<br /> - Простой, красивый дизайн!<br /> - Расслабляющий и спокойный, радостный, познавательный опыт работы с приложением;"])}
      },
      "es": {
        "aboutThisGameHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de este juego"])},
        "aboutThisGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego de buscar y encontrar. Busca objetos, figuras y objetos ocultos. Enigma para mejorar la memoria, aumentar la atención visual, el poder de concentración y las habilidades motoras. Refuerzo mental! Rompecabezas de aspecto simple e intuitivo! Esta es tu paz y calma para el día. ¡Pruébalo! Su brillante prueba de paciencia! Este es un nuevo juego gratuito de objetos ocultos.<br / >🌟 HIGHLIGHTS ASPECTOS DESTACADOS DEL JUEGO 🌟<br /> - Encuentra cosas y objetos. Casi los mismos detalles (idénticos); <br /> - Busque hasta 5 diferencias una al lado de la otra por imagen;<br /> - 1000 imágenes HD hermosas y alegres: animales, comida, habitaciones, naturaleza y más;<br /> - Ayuda a tus ojos. Acerque los objetos para notar y detectar acertijos ocultos; <br /> - Use pistas ilimitadas para detectar lo que es diferente en la medida de lo posible; <br /> - 1000 de desafiantes niveles y pistas de comparación; <br /> - ¡Diseño simple, hermoso y encantador!- Experiencia relajante y tranquila, alegre y cognitiva de la aplicación;"])}
      }
    }
  })
}
