
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'
import {Events, SignInClick} from '@/app/services/Events'
import {Scenario} from '@/app/services/Scenario'

export default {
    name: 'PageHeaderDesktop',
    components: {RouterLocalizationLink},
    computed: {
        logoSrcSet() {
            const x2 = require('@/assets/img/logo/logo@2x.webp')
            const x3 = require('@/assets/img/logo/logo@3x.webp')

            return `${x2} 2x, ${x3} 3x`
        }
    },
    methods: {
        signInClickHandler() {
            Events.registerEvent(SignInClick, {
                click: true
            })

            const scenario = Scenario.getInstance()
            scenario.autoSignIn()
        }
    }
}
