
import TextBox from '@/app/common/pkg/content/pkg/textBox/TextBox.vue'
import PageHeader from '@/app/common/pkg/pageHeader/PageHeader.vue'
import PageFooter from '@/app/common/pkg/pageFooter/PageFooter.vue'

export default {
    name: 'SimplePage',
    components: {PageFooter, PageHeader, TextBox},
    props: {
        header: {
            type: String,
            required: true
        }
    }
}
