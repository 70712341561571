
export default {
    name: 'GameStatistics',
    computed: {
        ageSrcSet() {
            const x2 = require('@/assets/img/icons/age/age@2x.webp')
            const x3 = require('@/assets/img/icons/age/age@3x.webp')

            return `${x2} 2x, ${x3} 3x`
        }
    }
}
