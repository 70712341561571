import axios from 'axios'
import {Domain} from '@/app/services/Domain'

declare const debug: any

export const BaseAPI = {
    get: async function(url: string, extraHeaders = {}, config = {}) {
        const domain = Domain.getInstance()
        const apiURL = domain.getApiUrl()
        const defaultConfig = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...extraHeaders
            }
        }
        debug.log(
            'GET',
            `${apiURL}${url}`,
            {
                ...defaultConfig,
                ...config
            }
        )
        return await axios.get(
            `${apiURL}${url}`,
            {
                ...defaultConfig,
                ...config
            }
        )
    },
    post: async function(url: string, data = {}, extraHeaders = {}, config = {}) {
        const domain = Domain.getInstance()
        const apiURL = domain.getApiUrl()
        const defaultConfig = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...extraHeaders
            }
        }
        debug.log(
            'POST',
            `${apiURL}${url}`,
            {
                ...data
            },
            {
                ...defaultConfig,
                ...config
            }
        )
        return await axios.post(
            `${apiURL}${url}`,
            {
                ...data
            },
            {
                ...defaultConfig,
                ...config
            }
        )
    }
}
