import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "iubenda-privacy-policy" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_page = _resolveComponent("simple-page")!

  return (_openBlock(), _createBlock(_component_simple_page, { header: "Privacy Policy" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          innerHTML: $data.privacyPolicy,
          class: "iub_content simple_pp"
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}