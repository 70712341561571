import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * For everyone Users
 */
export const AuthAPI = {
    create: async function(createToken: string) {
        return await BaseAPI.post(
            '/auth/create',
            {
                create_token: createToken
            }
        )
    },
    login: async function(email: string, password: string) {
        return await BaseAPI.post(
            '/auth/login',
            {
                email,
                password
            }
        )
    }
}
