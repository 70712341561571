
import SimplePage from '@/app/common/pkg/simplePage/SimplePage.vue'
import GameInfo from '@/app/common/pkg/content/pkg/gameBox/pkg/gameInfo/GameInfo.vue'
import {Scenario} from '@/app/services/Scenario'
import {SubscriberAPI} from '@/app/services/api/AuthorizedUsers/SubscriberAPI'
import {
    AuthorizedSubscriberApiGetStripeCustomerPortalLinkError,
    Events
} from '@/app/services/Events'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'
import WaitLoader from '@/app/common/pkg/content/pkg/waitLoader/WaitLoader.vue'

export default {
    name: 'MemberShip',
    components: {WaitLoader, GameInfo, SimplePage},
    data() {
        return {
            signedEmail: 'unknown@email.com',
            isHaveSubscription: false,
            isWaitLoader: false
        }
    },
    mounted() {
        const scenario = Scenario.getInstance()
        this.signedEmail = scenario.getSignedEmail()

        scenario.checkSubscriptionExists(
            () => {
                this.isHaveSubscription = scenario.isUserSubscriptionExists()
                if (!this.isHaveSubscription) {
                    this.$router.push({name: 'membership-sign-in'})
                }
            },
            () => {},
            'MemberShip.mounted()'
        )
    },
    methods: {
        openCustomerPortal() {
            this.debug.log('openCustomerPortal')
            this.isWaitLoader = true

            if (!AppLocalStorage.isUserTokenExists()) {
                this.debug.error('openCustomerPortal: user token does not exists')
                this.isWaitLoader = false
                return
            }

            const userToken = AppLocalStorage.getUserToken()

            SubscriberAPI.getStripeCustomerPortalLink(userToken)
                .then((result) => {
                    this.debug.log('openCustomerPortal: SubscriberAPI.getStripeCustomerPortalLink() success', result.data)
                    this.debug.log('Portal Opened')
                    this.window.open(result.data.url, '_self')
                })
                .catch((err) => {
                    this.debug.error('openCustomerPortal: SubscriberAPI.getStripeCustomerPortalLink() error', err)
                    this.isWaitLoader = false
                    Events.registerError(
                        AuthorizedSubscriberApiGetStripeCustomerPortalLinkError,
                        {
                            point: 'openCustomerPortal: SubscriberAPI.getStripeCustomerPortalLink() error',
                            userToken,
                            err
                        }
                    )
                })
        },
        cancelSubscription() {
            this.debug.log('cancelSubscription: click')

            const scenario = Scenario.getInstance()
            this.debug.log('cancelSubscription: this.isHaveSubscription = true')
            scenario.unsubscribeUser(() => {
                this.debug.log('Unsubscribe Logged User: user have not subscriptions')
                this.$router.push({name: 'main-page'})
            })
        }
    }
}
