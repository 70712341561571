
import PageHeaderMobile from '@/app/common/pkg/pageHeader/pkg/PageHeaderMobile.vue'
import PageHeaderDesktop from '@/app/common/pkg/pageHeader/pkg/PageHeaderDesktop.vue'

export default {
    name: 'PageHeader',
    components: {PageHeaderDesktop, PageHeaderMobile},
    props: {
        goToBackLink: {
            type: Boolean,
            required: false
        },
        hideLogo: {
            type: Boolean,
            required: false
        },
        hideMenu: {
            type: Boolean,
            required: false
        }
    }
}
