
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'
import {ContactUsClick, Events, MembershipClick, SignInClick} from '@/app/services/Events'
import {Scenario} from '@/app/services/Scenario'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'

export default {
    name: 'MainMenuItems',
    components: {RouterLocalizationLink},
    data() {
        return {
            isHaveSubscription: false
        }
    },
    mounted() {
        const scenario = Scenario.getInstance()
        scenario.checkSubscriptionExists(
            () => {
                this.isHaveSubscription = scenario.isUserSubscriptionExists() && (AppLocalStorage.getRememberMe() === 'yes')
            },
            () => {},
            'MainMenuItems.mounted()'
        )
    },
    methods: {
        signInClickHandler() {
            Events.registerEvent(SignInClick, {
                click: true
            })

            const scenario = Scenario.getInstance()
            scenario.autoSignIn()
        },
        contactUsHandler() {
            Events.registerEvent(ContactUsClick, {
                click: true
            })
        },
        membershipClickHandler() {
            Events.registerEvent(MembershipClick, {
                click: true
            })
        }
    }
}
