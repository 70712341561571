
import {Scenario} from '@/app/services/Scenario'

export default {
    name: 'UpdateSubscriptionEmailForm',
    data() {
        return {
            email: ''
        }
    },
    mounted() {
        this.focusInput()
    },
    methods: {
        focusInput() {
            this.$refs.email.focus()
        },
        updateSubscriptionEmail() {
            if (!this.isValidEmail) {
                alert('Email ' + this.email + ' is ' + (this.isValidEmail ? 'valid' : 'invalid'))

                return
            }

            // todo move logic inside scenario
            const scenario = Scenario.getInstance()
            scenario.updateSubscriptionEmail(this.email, () => {
                scenario.checkSubscriptionExists(
                    () => {
                        if (scenario.isUserSubscriptionExists()) {
                            scenario.routeToGame()
                        }
                    },
                    () => {},
                    'updateSubscriptionEmail()'
                )
            })
        }
    },
    computed: {
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email)
        }
    }
}
