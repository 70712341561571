
import CopyRight from '@/app/common/pkg/content/pkg/copyRight/CopyRight.vue'
import MainMenuItems from '@/app/common/pkg/content/pkg/mainMenu/pkg/MenuItems/MainMenuItems.vue'
import AvailableLanguagesSelect
    from '@/app/common/pkg/content/pkg/mainMenu/pkg/AvailableLanguages/AvailableLanguagesSelect.vue'

export default {
    name: 'PageFooterDesktop',
    components: {AvailableLanguagesSelect, MainMenuItems, CopyRight}
}
