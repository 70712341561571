import * as Sentry from '@sentry/vue'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'
import {ABTestGameTuningSteps, ABTestsEnabled} from '@/app/services/ABTests'

declare const fbq: any
declare const gtag: any
declare const ym: any

export const SignInClick = 'sign_in_click'
export const ContactUsClick = 'contact_us_click'
export const MembershipClick = 'membership_click'
// -----------------------------------
export const ApplePaySystemAvailable = 'apple_pay_system_available'
export const ApplePaySystemUnavailable = 'apple_pay_system_unavailable'
export const ApplePayCanMakePaymentsAvailable = 'apple_pay_can_make_payments_available'
export const ApplePayCanMakePaymentsUnavailable = 'apple_pay_can_make_payments_unavailable'
export const ApplePayStripeAllowDrawButton = 'apple_pay_stripe_allow_draw_button'
export const ApplePayStripeForbidDrawButton = 'apple_pay_stripe_forbid_draw_button'
export const ApplePayButtonVisible = 'apple_pay_button_visible'
export const ApplePayButtonHidden = 'apple_pay_button_hidden'
export const GooglePayButtonVisible = 'google_pay_button_visible'
export const GooglePayButtonHidden = 'google_pay_button_hidden'
// -----------------------------------
export const FirstButtonClick = 'first_button_click'
export const SecondButtonClick = 'second_button_click'
export const StartSubscriptionClick = 'start_subscription_click'
export const SubscriptionSuccess = 'subscription_success'
export const SubscriptionSuccessGoogle = 'subscription_success_google'
export const SubscriptionSuccessApple = 'subscription_success_apple'
export const GooglePayClick = 'google_pay_click'
export const ApplePayClick = 'apple_pay_click'
export const OpenGooglePayDialogue = 'open_google_pay_dialogue'
export const OpenApplePayDialogue = 'open_apple_pay_dialogue'
// -----------------------------------
export const AuthorizedAuthApiError = 'authorized_auth_api_error'
export const AuthorizedSubscriberApiError = 'authorized_subscriber_api_error'
export const UnauthorizedABTestsApiError = 'unauthorized_abtests_api_error'
export const AuthorizedSubscriberApiGetStripeCustomerPortalLinkError = 'authorized_subscriber_api_get_stripe_customer_portal_link_error'
export const UnauthorizedApplePaymentsApiError = 'unauthorized_apple_payments_api_error'
export const UnauthorizedAuthApiError = 'unauthorized_auth_api_error'
export const UnauthorizedExternalDataApi = 'unauthorized_external_data_api'
export const UnauthorizedGooglePaymentsApiError = 'unauthorized_google_payments_api_error'
export const ApplePaymentsError = 'apple_payments_error'
export const GooglePaymentsError = 'google_payments_error'
export const ScenarioError = 'scenario_error'
export const AsyncScriptError = 'async_script_error'

export const Events = {
    registerEvent: function(name: string, data) {
        if (ABTestsEnabled) {
            const testIdent = ABTestGameTuningSteps
            if (AppLocalStorage.isABTestExists(testIdent)) {
                const abTestValue: string = AppLocalStorage.getABTest(testIdent)
                name = `${name}_ab_${abTestValue}`
            }
        }
        gtag('event', name, data)
        fbq('trackCustom', name, data)
        ym(93842558, 'reachGoal', name)
    },
    registerError: function(name: string, data) {
        if (ABTestsEnabled) {
            const testIdent = ABTestGameTuningSteps
            if (AppLocalStorage.isABTestExists(testIdent)) {
                const abTestValue: string = AppLocalStorage.getABTest(testIdent)
                name = `${name}_ab_${abTestValue}`
            }
        }
        gtag('event', name, data)
        Sentry.captureException(new Error(name), {extra: data})
    }
}
