
import SimplePage from '@/app/common/pkg/simplePage/SimplePage.vue'
import {ExternalDataAPI} from '@/app/services/api/UnauthorizedUsers/ExternalDataAPI'

export default {
    name: 'PrivacyPolicy',
    data() {
        return {
            privacyPolicy: ''
        }
    },
    components: {SimplePage},
    methods: {
        getPrivacyPolicy() {
            const self = this
            const promisePrivacyPolicy = ExternalDataAPI.getPrivacyPolicy()
            promisePrivacyPolicy.then((result) => {
                self.privacyPolicy = result.data.content
            })
        }
    },
    mounted() {
        this.getPrivacyPolicy()
    }
}
