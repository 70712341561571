
import PageHeader from '@/app/common/pkg/pageHeader/PageHeader.vue'
import TextBox from '@/app/common/pkg/content/pkg/textBox/TextBox.vue'
import SwiperBox from '@/app/common/pkg/content/pkg/swiperBox/SwiperBox.vue'
import GameBox from '@/app/common/pkg/content/pkg/gameBox/GameBox.vue'
import PageFooter from '@/app/common/pkg/pageFooter/PageFooter.vue'
import DebugButtons from '@/app/common/pkg/content/pkg/debugButtons/DebugButtons.vue'
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'
import {Events, FirstButtonClick} from '@/app/services/Events'

export default {
    name: 'MainPage',
    components: {
        RouterLocalizationLink,
        DebugButtons,
        PageFooter,
        GameBox,
        SwiperBox,
        PageHeader,
        TextBox
    },
    methods: {
        onSuccessProcessPayment() {
            this.debug.log('onSuccessProcessPayment')
            this.$router.push({name: 'update-email'})
            this.debug.log('onSuccessProcessPayment after router push')
        },
        playNowClickHandler() {
            Events.registerEvent(FirstButtonClick, {
                click: true
            })
        }
    },
    data() {
        return {
            images: [
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-1.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-1.webp'),
                    alt: 'TapTap - Screen 1',
                    width: 520,
                    height: 925
                },
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-2.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-2.webp'),
                    alt: 'TapTap - Screen 2',
                    width: 520,
                    height: 925
                },
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-3.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-3.webp'),
                    alt: 'TapTap - Screen 3',
                    width: 520,
                    height: 925
                },
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-4.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-4.webp'),
                    alt: 'TapTap - Screen 4',
                    width: 520,
                    height: 925
                },
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-5.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-5.webp'),
                    alt: 'TapTap - Screen 5',
                    width: 520,
                    height: 925
                },
                {
                    largeURL: require('@/assets/img/games/taptap/swiper/taptap-screen-6.webp'),
                    thumbnailURL: require('@/assets/img/games/taptap/swiper/taptap-screen-6.webp'),
                    alt: 'TapTap - Screen 6',
                    width: 520,
                    height: 925
                }
            ]
        }
    }
}
