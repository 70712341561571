

import {defineComponent} from 'vue'
import PageHeader from '@/app/common/pkg/pageHeader/PageHeader.vue'
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'
import {Events, SecondButtonClick} from '@/app/services/Events'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'
import {ABTestGameTuningSteps, ABTestsEnabled} from '@/app/services/ABTests'

export default defineComponent({
    components: {RouterLocalizationLink, PageHeader},
    data() {
        return {
            gameDifficulty: 'medium'
        }
    },
    mounted() {
        this.loadGameSettings()
    },
    methods: {
        loadGameSettings() {
            if (AppLocalStorage.isGameSettingsDifficulty()) {
                this.gameDifficulty = AppLocalStorage.getGameSettingsDifficulty()
            }
        },
        saveGameSettings() {
            AppLocalStorage.setGameSettingsDifficulty(this.gameDifficulty)
        },
        selectDifficulty(difficulty) {
            this.gameDifficulty = difficulty
            this.saveGameSettings()
        },
        nextClickHandler() {
            Events.registerEvent(SecondButtonClick, {
                click: true
            })
            this.saveGameSettings()
        }
    },
    computed: {
        containerClass() {
            if (ABTestsEnabled) {
                if (AppLocalStorage.isABTestExists(ABTestGameTuningSteps)) {
                    const abTestValue = AppLocalStorage.getABTest(ABTestGameTuningSteps)
                    return `content game-tuning-steps-${abTestValue} game-tuning-steps select-game-difficulty`
                }
            }
            return 'content game-tuning-steps-white game-tuning-steps select-game-difficulty'
        },
        btnBase() {
            return 'btn btn-difficulty my-2'
        },
        btnDifficultyEasy() {
            if (this.gameDifficulty === 'easy') {
                return this.btnBase + ' btn-difficulty__active'
            }

            return this.btnBase
        },
        btnDifficultyMedium() {
            if (this.gameDifficulty === 'medium') {
                return this.btnBase + ' btn-difficulty__active'
            }

            return this.btnBase
        },
        btnDifficultyHard() {
            if (this.gameDifficulty === 'hard') {
                return this.btnBase + ' btn-difficulty__active'
            }

            return this.btnBase
        }
    }
})
