

import {defineComponent} from 'vue'
import PageHeader from '@/app/common/pkg/pageHeader/PageHeader.vue'
import GooglePayButton from '@/app/common/pkg/content/pkg/payButtons/googlePayButton/GooglePayButton.vue'
import ApplePayButton from '@/app/common/pkg/content/pkg/payButtons/applePayButton/ApplePayButton.vue'
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'
import {Events, StartSubscriptionClick} from '@/app/services/Events'
import {Scenario} from '@/app/services/Scenario'
import PageFooter from '@/app/common/pkg/pageFooter/PageFooter.vue'
import GameMoreInfo from '@/app/common/pkg/content/pkg/gameBox/pkg/gameMoreInfo/GameMoreInfo.vue'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'
import {ABTestGameTuningSteps, ABTestsEnabled} from '@/app/services/ABTests'

export default defineComponent({
    components: {GameMoreInfo, PageFooter, RouterLocalizationLink, ApplePayButton, GooglePayButton, PageHeader},
    methods: {
        playFreeClickHandler() {
            setTimeout(() => {
                Events.registerEvent(StartSubscriptionClick, {
                    click: true
                })
            }, 100)
            const scenario = Scenario.getInstance()
            scenario.playFreeClick()
        }
    },
    computed: {
        containerClass() {
            if (ABTestsEnabled) {
                if (AppLocalStorage.isABTestExists(ABTestGameTuningSteps)) {
                    const abTestValue = AppLocalStorage.getABTest(ABTestGameTuningSteps)
                    return `content game-tuning-steps-${abTestValue} game-tuning-steps your-game-is-ready`
                }
            }
            return 'content game-tuning-steps-white game-tuning-steps your-game-is-ready'
        }
    }
})
