import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * For everyone Users
 */
export const ExternalDataAPI = {
    getPrivacyPolicy: async function() {
        return await BaseAPI.get('/external-data/privacy-policy')
    },
    getCookiePolicy: async function() {
        return await BaseAPI.get('/external-data/cookie-policy')
    }
}
