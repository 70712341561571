import {GooglePaymentsAPI} from '@/app/services/api/UnauthorizedUsers/GooglePaymentsAPI'
import {Scenario} from '@/app/services/Scenario'
import {AsyncScript} from '@/app/services/html/AsyncScript'
import {
    AsyncScriptError,
    Events,
    GooglePayButtonHidden,
    GooglePayButtonVisible,
    GooglePayClick, GooglePaymentsError,
    OpenGooglePayDialogue, UnauthorizedGooglePaymentsApiError
} from '@/app/services/Events'

declare const google: any
declare const debug: any

export class GooglePayments {
    private static instance: GooglePayments
    private elementId: string = ''
    private onSuccessProcessPayment: any
    private googlePaymentsClient: any = null
    private isGooglePayAvailable: Boolean = false
    private googlePaymentToken: any = null

    private constructor() {
    }

    public static getInstance(): GooglePayments {
        if (!(GooglePayments.instance instanceof GooglePayments)) {
            GooglePayments.instance = new GooglePayments()
        }

        return GooglePayments.instance
    }

    /**
     * 0
     */
    isGooglePayButtonAvailable(): Boolean {
        return this.isGooglePayAvailable
    }

    /**
     * 1.
     */
    loadGooglePay(elementId: string, onSuccessProcessPayment): void {
        this.elementId = elementId
        this.onSuccessProcessPayment = onSuccessProcessPayment

        if (this.googlePaymentsClient === null) {
            AsyncScript.create(
                'https://pay.google.com/gp/p/js/pay.js',
                () => {
                    this.onGooglePayLoaded(this.elementId)
                },
                () => {
                    Events.registerError(
                        AsyncScriptError,
                        {
                            point: 'https://pay.google.com/gp/p/js/pay.js'
                        }
                    )
                }
            )
        } else {
            this.onGooglePayLoaded(this.elementId)
        }
    }

    /**
     * 2. Return an active PaymentsClient or initialize
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
     * @returns {google.payments.api.PaymentsClient} Google Pay API client
     */
    getGooglePaymentsClient(): any {
        if (this.googlePaymentsClient === null) {
            debug.log('GooglePayments: getGooglePaymentsClient')
            this.googlePaymentsClient = new google.payments.api.PaymentsClient({
                environment: 'PRODUCTION',
                merchantInfo: {
                    merchantName: 'Brightika Inc.',
                    merchantId: 'BCR2DN4TZK33BTKO'
                },
                paymentDataCallbacks: {
                    onPaymentAuthorized: async(paymentData): Promise<any> => {
                        return await this.onPaymentAuthorized(paymentData)
                    }
                }
            })
        }

        return this.googlePaymentsClient
    }

    /**
     * 3. Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
     *
     * Display a Google Pay payment button after confirmation of the viewer's
     * ability to pay.
     */
    onGooglePayLoaded(elementId: string): void {
        const onSuccess = (isReadyToPayRequest): void => {
            const paymentsClient = this.getGooglePaymentsClient()
            debug.log('GooglePayments: paymentsClient', isReadyToPayRequest)
            paymentsClient.isReadyToPay(isReadyToPayRequest)
                .then((response) => {
                    if (response.result === true) {
                        this.isGooglePayAvailable = true
                        setTimeout(() => {
                            Events.registerEvent(GooglePayButtonVisible, {})
                        }, 100)
                        this.addGooglePayButton(elementId, isReadyToPayRequest.allowedPaymentMethods)
                    } else {
                        setTimeout(() => {
                            Events.registerEvent(GooglePayButtonHidden, {})
                        }, 100)
                        debug.error('GooglePayments: paymentsClient', response.result)
                        Events.registerError(
                            GooglePaymentsError,
                            {
                                point: 'GooglePayments: paymentsClient B',
                                response
                            }
                        )
                    }
                })
                .catch((err) => {
                    // show error in developer console for debugging
                    debug.error('GooglePayments: paymentsClient', err)
                    Events.registerError(
                        GooglePaymentsError,
                        {
                            point: 'GooglePayments: paymentsClient A',
                            err
                        }
                    )
                })
        }

        this.getGoogleIsReadyToPayRequest(onSuccess)
    }

    /**
     * 4. Configure your site's support for payment methods supported by the Google Pay API.
     *
     * Each member of allowedPaymentMethods should contain only the required fields,
     * allowing reuse of this base request when determining a viewer's ability
     * to pay and later requesting a supported payment method
     *
     * @returns {object} Google Pay API version, payment methods supported by the site
     */
    getGoogleIsReadyToPayRequest(onSuccess): void {
        GooglePaymentsAPI.getReadyCheckObject()
            .then((result) => {
                debug.info('GooglePayments: getGoogleIsReadyToPayRequest() success', result)
                onSuccess(result.data)
            })
            .catch((err) => {
                debug.error('GooglePayments: getGoogleIsReadyToPayRequest() error', err)
                Events.registerError(
                    UnauthorizedGooglePaymentsApiError,
                    {
                        point: 'GooglePayments: getGoogleIsReadyToPayRequest() error',
                        err
                    }
                )
            })
    }

    /**
     * 5. Add a Google Pay purchase button alongside an existing checkout button
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
     * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
     */
    addGooglePayButton(elementId, allowedPaymentMethods): void {
        const paymentsClient = this.getGooglePaymentsClient()

        const button = paymentsClient.createButton({
            buttonColor: 'black',
            buttonType: 'plain',
            buttonSizeMode: 'fill',
            onClick: () => {
                setTimeout(() => {
                    Events.registerEvent(GooglePayClick, {
                        click: true
                    })
                }, 100)

                this.onGooglePaymentButtonClicked()
            },
            allowedPaymentMethods
        })

        const element = document.getElementById(elementId)
        if (element instanceof HTMLElement) {
            element.appendChild(button)
        }
    }

    /**
     * 6. Show Google Pay payment sheet when Google Pay payment button is clicked
     */
    onGooglePaymentButtonClicked(): void {
        const paymentsClient = this.getGooglePaymentsClient()

        const onSuccess = (paymentDataRequest): void => {
            debug.info('GooglePayments: paymentsClient.loadPaymentData', paymentDataRequest)
            setTimeout(() => {
                Events.registerEvent(OpenGooglePayDialogue, {
                    click: true
                })
            }, 100)
            paymentsClient.loadPaymentData(paymentDataRequest)
                .then((paymentData) => {
                    // if using gateway tokenization, pass this token without modification
                    this.googlePaymentToken = paymentData.paymentMethodData.tokenizationData.token
                    debug.info('GooglePayments: googlePaymentToken', this.googlePaymentToken)
                    // Here Call API Create Subscription Google
                })
                .catch((err) => {
                    // show error in developer console for debugging
                    debug.error('GooglePayments: createToken error', err)
                    Events.registerError(
                        GooglePaymentsError,
                        {
                            point: 'GooglePayments: createToken error',
                            err
                        }
                    )
                })
        }

        this.getGooglePaymentDataRequest(onSuccess)
    }

    /**
     * 7. Configure support for the Google Pay API
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
     * @returns {object} PaymentDataRequest fields
     */
    getGooglePaymentDataRequest(onSuccess): void {
        GooglePaymentsAPI.getPaymentRequestObject()
            .then((result) => {
                debug.info('GooglePayments: GooglePaymentsAPI.getPaymentRequestObject() success', result.data.request)
                onSuccess(result.data.request)
            })
            .catch((err) => {
                debug.error('GooglePayments: GooglePaymentsAPI.getPaymentRequestObject() error', err)
                Events.registerError(
                    UnauthorizedGooglePaymentsApiError,
                    {
                        point: 'GooglePayments: GooglePaymentsAPI.getPaymentRequestObject() error',
                        err
                    }
                )
            })
    }

    /**
     * 8. Handles authorize payments callback intents.
     *
     * @param {object} paymentData response from Google Pay API after a payer approves payment through user gesture.
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData object reference}
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentAuthorizationResult}
     * @returns Promise<{object}> Promise of PaymentAuthorizationResult object to acknowledge the payment authorization status.
     */
    async onPaymentAuthorized(paymentData): Promise<any> {
        return await new Promise((resolve, reject) => {
            // handle the response
            this.processPayment(
                paymentData,
                (paymentToken, onSuccessScenarioProcessPayment) => {
                    const scenario = Scenario.getInstance()
                    scenario.processPayment(
                        'GooglePay',
                        paymentToken,
                        () => {
                            this.onSuccessProcessPayment()
                            onSuccessScenarioProcessPayment()
                        }
                    )
                })
                .then(() => {
                    resolve({transactionState: 'SUCCESS'})
                })
                .catch(() => {
                    resolve({
                        transactionState: 'ERROR',
                        error: {
                            intent: 'PAYMENT_AUTHORIZATION',
                            message: 'Insufficient funds, please try again.',
                            reason: 'PAYMENT_DATA_INVALID'
                        }
                    })
                    Events.registerError(
                        GooglePaymentsError,
                        {
                            point: 'onPaymentAuthorized'
                        }
                    )
                })
        })
    }

    /**
     * 9. Process payment data returned by the Google Pay API
     *
     * @param {object} paymentData response from Google Pay API after user approves payment
     * @param createSubscriptionCall
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
     */
    async processPayment(paymentData, createSubscriptionCall): Promise<any> {
        // const addGooglePaymentAttempt = this.addGooglePaymentAttempt
        return await new Promise((resolve, reject) => {
            setTimeout(() => {
                createSubscriptionCall(
                    paymentData.paymentMethodData.tokenizationData.token,
                    () => {
                        resolve({})
                    }
                )
            }, 3000)
        })
    }
}
