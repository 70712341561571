declare const debug: any
declare const window: any

export class Domain {
    private static instance: Domain

    private constructor() {
    }

    public static getInstance(): Domain {
        if (!(Domain.instance instanceof Domain)) {
            Domain.instance = new Domain()
        }

        return Domain.instance
    }

    getBaseUrl(): string {
        const domain = window.location.host

        if (domain === 'playgames365.net') {
            debug.log('getBaseUrl', 'https://playgames365.net/')
            return 'https://playgames365.net/'
        }

        if (domain === 'dev.playgames365.net') {
            debug.log('getBaseUrl', 'https://dev.playgames365.net/')
            return 'https://dev.playgames365.net/'
        }

        debug.log('getBaseUrl', 'https://localhost/')
        return 'https://localhost/'
    }

    getApiUrl(): string {
        const domain = window.location.host

        if (domain === 'playgames365.net') {
            debug.log('getApiUrl', 'https://api.playgames365.net/api')
            return 'https://api.playgames365.net/api'
        }

        if (domain === 'dev.playgames365.net') {
            debug.log('getApiUrl', 'https://dev-api.playgames365.net/api')
            return 'https://dev-api.playgames365.net/api'
        }

        debug.log('getApiUrl', 'https://localhost/api')
        return 'https://localhost/api'
    }

    getSentryDsn(): string {
        const domain = window.location.host

        if (domain === 'playgames365.net') {
            debug.log('getSentryDsn', 'https://9a2cc6e99305407aa53dae7e961b8cab@o1290620.ingest.sentry.io/4505306200735744')
            return 'https://9a2cc6e99305407aa53dae7e961b8cab@o1290620.ingest.sentry.io/4505306200735744'
        }

        if (domain === 'dev.playgames365.net') {
            debug.log('getSentryDsn', 'https://4d43d879064a4698bedfdf236bd964bf@o1290620.ingest.sentry.io/4505323373658112')
            return 'https://4d43d879064a4698bedfdf236bd964bf@o1290620.ingest.sentry.io/4505323373658112'
        }

        debug.log('getSentryDsn', 'https://localhost/sentryDsn')
        return 'https://localhost/sentryDsn'
    }

    getSentryTracePropagationTargets(): any {
        const domain = window.location.host

        if (domain === 'playgames365.net') {
            debug.log('getSentryTracePropagationTargets', ['localhost', /^https:\/\/api\.playgames365\.net\/api/])
            return ['localhost', /^https:\/\/api\.playgames365\.net\/api/]
        }

        if (domain === 'dev.playgames365.net') {
            debug.log('getSentryTracePropagationTargets', ['localhost', /^https:\/\/dev-api\.playgames365\.net\/api/])
            return ['localhost', /^https:\/\/dev-api\.playgames365\.net\/api/]
        }

        debug.log('getSentryTracePropagationTargets', ['localhost'])
        return ['localhost']
    }
}
