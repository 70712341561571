export const AsyncScript = {
    create: function(url: string, onLoad: any, onError: any) {
        const script = document.createElement('script')
        script.setAttribute('src', url)
        script.setAttribute('async', 'true')
        script.onload = onLoad
        script.onerror = onError
        document.head.appendChild(script)
    }
}
