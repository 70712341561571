import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-footer-desktop" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 pt-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-9" }
const _hoisted_7 = { class: "col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_right = _resolveComponent("copy-right")!
  const _component_main_menu_items = _resolveComponent("main-menu-items")!
  const _component_available_languages_select = _resolveComponent("available-languages-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_copy_right)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_main_menu_items)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_available_languages_select)
        ])
      ])
    ])
  ]))
}