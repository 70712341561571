import {ApplePaymentsAPI} from '@/app/services/api/UnauthorizedUsers/ApplePaymentsAPI'
import {Scenario} from '@/app/services/Scenario'
import {AsyncScript} from '@/app/services/html/AsyncScript'
import {
    ApplePayButtonHidden,
    ApplePayButtonVisible,
    ApplePayCanMakePaymentsAvailable,
    ApplePayCanMakePaymentsUnavailable,
    ApplePayClick,
    ApplePaymentsError,
    ApplePayStripeAllowDrawButton,
    ApplePayStripeForbidDrawButton,
    ApplePaySystemAvailable, ApplePaySystemUnavailable,
    AsyncScriptError,
    Events,
    OpenApplePayDialogue,
    UnauthorizedApplePaymentsApiError
} from '@/app/services/Events'
import {ApplePayButton} from '@/app/services/html/ApplePayButton'

declare const Stripe: any
declare const debug: any
declare const window: any

export class ApplePayments {
    private static instance: ApplePayments
    private elementId: string = ''
    private onSuccessProcessPayment: any
    private appleStripePaymentsClient: any = null
    private isApplePayAvailable: Boolean = false
    private paymentRequestObject: any

    private constructor() {
    }

    public static getInstance(): ApplePayments {
        if (!(ApplePayments.instance instanceof ApplePayments)) {
            ApplePayments.instance = new ApplePayments()
        }

        return ApplePayments.instance
    }

    /**
     * 0
     */
    isApplePayButtonAvailable(): Boolean {
        return this.isApplePayAvailable
    }

    /**
     * 1.
     */
    loadApplePay(elementId: string, onSuccessProcessPayment: any): void {
        this.elementId = elementId
        this.onSuccessProcessPayment = onSuccessProcessPayment

        if ((window.ApplePaySession != null) && (window.ApplePaySession.canMakePayments() === true)) {
            setTimeout(() => {
                Events.registerEvent(ApplePaySystemAvailable, {})
            }, 100)
            if (this.appleStripePaymentsClient === null) {
                AsyncScript.create(
                    'https://js.stripe.com/v3/',
                    () => {
                        this.onApplePayStripeLoaded(elementId, onSuccessProcessPayment)
                    },
                    () => {
                        Events.registerError(
                            AsyncScriptError,
                            {
                                point: 'https://js.stripe.com/v3/'
                            }
                        )
                    }
                )
            } else {
                this.onApplePayStripeLoaded(elementId, onSuccessProcessPayment)
            }
        } else {
            setTimeout(() => {
                Events.registerEvent(ApplePaySystemUnavailable, {})
            }, 100)
        }
    }

    /**
     * 2. Return an active PaymentsClient or initialize
     *
     * @param stripePK
     */
    getApplePaymentsStripeClient(stripePK: string): any {
        if (this.appleStripePaymentsClient === null) {
            this.appleStripePaymentsClient = new Stripe(stripePK)
        }

        return this.appleStripePaymentsClient
    }

    /**
     * 3. Initialize Apple Stripe PaymentsClient after Stripe-hosted JavaScript has loaded
     */
    onApplePayStripeLoaded(elementId: string, onSuccessProcessPayment: any): void {
        const onSuccess = (stripePK, request): void => {
            const paymentsClient = this.getApplePaymentsStripeClient(stripePK)
            //
            debug.log('ApplePay: paymentsClient', paymentsClient !== null)
            debug.log('ApplePay: PaymentRequest', request)
            this.paymentRequestObject = paymentsClient.paymentRequest(request)
            //
            debug.log('Apple paymentRequestObject', this.paymentRequestObject)
            this.paymentRequestObject.canMakePayment()
                .then((result) => {
                    debug.info('this.canMakePayment() success', result)
                    if (result != null) {
                        if (result.applePay === true) {
                            setTimeout(() => {
                                Events.registerEvent(ApplePayStripeAllowDrawButton, {result})
                            }, 100)
                        } else {
                            setTimeout(() => {
                                Events.registerEvent(ApplePayStripeForbidDrawButton, {result})
                            }, 100)
                        }
                        this.isApplePayAvailable = true
                        setTimeout(() => {
                            Events.registerEvent(ApplePayCanMakePaymentsAvailable, {result})
                        }, 100)
                        setTimeout(() => {
                            Events.registerEvent(ApplePayButtonVisible, {result})
                        }, 100)
                        this.addApplePayButton(stripePK, elementId, this.paymentRequestObject, onSuccessProcessPayment)
                    } else {
                        setTimeout(() => {
                            Events.registerEvent(ApplePayCanMakePaymentsUnavailable, {result})
                        }, 100)
                        setTimeout(() => {
                            Events.registerEvent(ApplePayButtonHidden, {result})
                        }, 100)
                    }
                })
                .catch((err) => {
                    debug.error('this.canMakePayment() error', err)
                    Events.registerError(
                        ApplePaymentsError,
                        {
                            point: 'this.canMakePayment() error',
                            err
                        }
                    )
                })
        }

        this.getApplePaymentRequestObject(onSuccess)
    }

    /**
     * 4.
     *
     * @param onSuccess
     */
    getApplePaymentRequestObject(onSuccess: any): void {
        ApplePaymentsAPI.getPaymentRequestObject()
            .then((result) => {
                debug.log('ApplePaymentsAPI.getPaymentRequestObject() success', result.data.request)
                onSuccess(result.data.stripePK, result.data.request)
            })
            .catch((err) => {
                debug.log('ApplePaymentsAPI.getPaymentRequestObject() error', err)
                Events.registerError(
                    UnauthorizedApplePaymentsApiError,
                    {
                        point: 'ApplePaymentsAPI.getPaymentRequestObject() error',
                        err
                    }
                )
            })
    }

    /**
     * 5.
     *
     * @param stripePK
     * @param elementId
     * @param paymentRequest
     * @param onSuccessProcessPayment
     */
    addApplePayButton(stripePK: string, elementId: string, paymentRequest: any, onSuccessProcessPayment): void {
        debug.log('addPayButtonToElementID', {paymentRequestObject: paymentRequest})
        //
        // const paymentsClient = this.getApplePaymentsStripeClient(stripePK)
        // const elements = paymentsClient.elements()
        // const prButton = elements.create('paymentRequestButton', {paymentRequest})
        //
        // prButton.mount('#' + elementId)
        ApplePayButton.create(elementId, () => {
            setTimeout(() => {
                Events.registerEvent(ApplePayClick, {
                    click: true
                })
                Events.registerEvent(OpenApplePayDialogue, {
                    click: true
                })
            }, 100)
            this.onApplePaymentButtonClicked()
        })
        // prButton.on('click', function(event) {
        //     setTimeout(() => {
        //         Events.registerEvent(ApplePayClick, {
        //             click: true
        //         })
        //         Events.registerEvent(OpenApplePayDialogue, {
        //             click: true
        //         })
        //     }, 100)
        // })
        paymentRequest.on('token', (event) => {
            // Here Create Subscription
            // event.token is available
            const paymentToken = JSON.stringify(event.token)
            debug.info(paymentToken)
            // Here Call API Create Subscription Google
            const scenario = Scenario.getInstance()
            scenario.processPayment(
                'ApplePay',
                paymentToken,
                () => {
                    event.complete('success')
                    onSuccessProcessPayment()
                }
            )
        })
    }

    /**
     * 6. Show Apple Pay payment sheet when Google Pay payment button is clicked
     */
    onApplePaymentButtonClicked(): void {
        setTimeout(() => {
            Events.registerEvent(OpenApplePayDialogue, {
                click: true
            })
        }, 100)
        this.paymentRequestObject.show()
    }
}
