import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * For everyone Users
 */
export const ABTestsAPI = {
    getABTest: async function(testIdent: string) {
        return await BaseAPI.get(`/ab-test?testIdent=${testIdent}`)
    }
}
