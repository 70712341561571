
import {ApplePayments} from '@/app/services/payments/ApplePayments'
import {Events, SubscriptionSuccessApple, SubscriptionSuccess} from '@/app/services/Events'

export default {
    name: 'ApplePayButton',
    mounted() {
        this.debug.log('Apple Pay Mounted Start')
        // Apple Pay
        const applePayments = ApplePayments.getInstance()
        applePayments.loadApplePay('applePayBtnPlace', this.onSuccessProcessPayment)
        this.debug.log('Apple Pay Mounted End')
    },
    methods: {
        onSuccessProcessPayment() {
            setTimeout(() => {
                Events.registerEvent(SubscriptionSuccessApple, {
                    click: true
                })
                Events.registerEvent(SubscriptionSuccess, {
                    click: true
                })
            }, 100)
            this.debug.log('onSuccessProcessPayment Apple')
            this.$router.push({name: 'update-email'})
            this.debug.log('onSuccessProcessPayment Apple after router push')
        }
    }
}
