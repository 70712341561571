
import {Scenario} from '@/app/services/Scenario'

export default {
    name: 'SignInForm',
    props: {
        unsubscribe: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            email: '',
            rememberMe: false,
            isEmailValid: true,
            hasErrors: false
        }
    },
    methods: {
        emailInput() {
            this.isEmailValid = true
        },
        checkIsEmailValid() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email)
        },
        signIn() {
            this.debug.log('signInForm: signIn click')

            this.hasErrors = false

            if (!this.checkIsEmailValid()) {
                this.isEmailValid = false

                return
            }

            this.isEmailValid = true

            // todo move logic inside scenario
            const scenario = Scenario.getInstance()
            scenario.signIn(
                this.email,
                this.rememberMe,
                () => {
                    if (scenario.isUserSubscriptionExists()) {
                        if (this.unsubscribe) {
                            this.$router.push({name: 'membership'})
                        } else {
                            scenario.routeToGame()
                        }
                    } else {
                        this.debug.log('SignInForm: user have not subscriptions')
                        this.$router.push({name: 'main-page'})
                    }
                },
                () => {
                    this.hasErrors = true
                }
            )
        }
    },
    computed: {
        isValidEmailMessage() {
            return 'Email ' + this.email + ' is ' + (this.isValidEmail ? 'valid' : 'invalid')
        },
        errorsMessage() {
            return 'An error has occurred, please contact support'
        },
        logoSrcSet() {
            const x2 = require('@/assets/img/logo/logo-big@2x.webp')
            const x3 = require('@/assets/img/logo/logo-big@3x.webp')

            return `${x2} 2x, ${x3} 3x`
        }
    }
}
