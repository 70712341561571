import {createRouter, createWebHistory} from 'vue-router'
import NotFound from '@/app/pages/notFound/NotFound.vue'
import MainPage from '@/app/pages/mainPage/MainPage.vue'
import TermsOfService from '@/app/pages/termsOfService/TermsOfService.vue'
import PrivacyPolicy from '@/app/pages/privacyPolicy/PrivacyPolicy.vue'
import SignIn from '@/app/pages/signIn/SignIn.vue'
import AboutUs from '@/app/pages/aboutUs/AboutUs.vue'
import ContactUs from '@/app/pages/contactUs/ContactUs.vue'
import CookiePolicy from '@/app/pages/cookiePolicy/CookiePolicy.vue'
import MemberShip from '@/app/pages/memberShip/MemberShip.vue'
import DoNotSellMyPersonalInformation
    from '@/app/pages/doNotSellMyPersonalInformation/DoNotSellMyPersonalInformation.vue'

import {defaultLocale} from '../../i18n'
import UpdateSubscriptionEmail from '@/app/pages/updateSubscriptionEmail/UpdateSubscriptionEmail.vue'
import MemberShipSignIn from '@/app/pages/memberShipSignIn/MemberShipSignIn.vue'
import SelectGameDifficulty from '@/app/pages/selectGameDifficulty/SelectGameDifficulty.vue'
import YourGameIsReady from '@/app/pages/yourGameIsReady/YourGameIsReady.vue'

const routes = [
    {
        path: '/',
        redirect: `/${defaultLocale}`
    },
    {
        path: '/:locale(en|fr|de|tr|pl|pt|zn|ru|es)',
        children: [
            {
                path: '',
                component: MainPage,
                name: 'main-page'
            },
            {
                path: 'select-game-difficulty',
                component: SelectGameDifficulty,
                name: 'select-game-difficulty'
            },
            {
                path: 'your-game-is-ready',
                component: YourGameIsReady,
                name: 'your-game-is-ready'
            },
            {
                path: 'sign-in',
                component: SignIn,
                name: 'sign-in'
            },
            {
                path: 'update-email',
                component: UpdateSubscriptionEmail,
                name: 'update-email'
            },
            {
                path: 'about-us',
                component: AboutUs,
                name: 'about-us'
            },
            {
                path: 'contact-us',
                component: ContactUs,
                name: 'contact-us'
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicy,
                name: 'privacy-policy'
            },
            {
                path: 'terms-of-service',
                component: TermsOfService,
                name: 'terms-of-service'
            },
            {
                path: 'cookie-policy',
                component: CookiePolicy,
                name: 'cookie-policy'
            },
            {
                path: 'membership',
                component: MemberShip,
                name: 'membership'
            },
            {
                path: 'membership-sign-in',
                component: MemberShipSignIn,
                name: 'membership-sign-in'
            },
            {
                path: 'do-not-sell-my-personal-information',
                component: DoNotSellMyPersonalInformation,
                name: 'do-not-sell-my-personal-information'
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: 'notFound'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if ((to.hash !== null) && (to.hash.length > 0)) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        }

        return {top: 0}
    }
})

export default router
