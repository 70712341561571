<template>
    <router-view />
</template>

<script>
import 'bootstrap'

export default {
    name: 'App'
}
</script>

<style lang="scss">
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~@/app/styles/main.scss";
</style>
