import Bowser from 'bowser'
import {AppLocalStorage} from '@/app/services/AppLocalStorage'
import {AuthAPI} from '@/app/services/api/UnauthorizedUsers/AuthAPI'
import {SubscriberAPI} from '@/app/services/api/AuthorizedUsers/SubscriberAPI'
import {GooglePayments} from '@/app/services/payments/GooglePayments'
import {ApplePayments} from '@/app/services/payments/ApplePayments'
import {UrlTags} from '@/app/services/UrlTags'
import {
    AuthorizedSubscriberApiError,
    Events, ScenarioError, UnauthorizedABTestsApiError,
    UnauthorizedAuthApiError
} from '@/app/services/Events'
import {ABTestsAPI} from '@/app/services/api/UnauthorizedUsers/ABTestsAPI'
import {ABTestGameTuningSteps, ABTestsEnabled} from '@/app/services/ABTests'

declare const debug: any
declare const window: any

// Тоже Переписать на класс со стейтом
// На главной странице сразу получить стейт - для кнопки PlayFree про подписки - есть нет
// и при клике сразу звать нужный боттомшит
export class Scenario {
    private static instance: Scenario
    private isSubscriptionExists: boolean = false
    private browserName: string | undefined = ''

    private constructor() {
    }

    public static getInstance(): Scenario {
        if (!(Scenario.instance instanceof Scenario)) {
            Scenario.instance = new Scenario()
        }

        return Scenario.instance
    }

    isBrowserAppleSafari(): boolean {
        if (this.browserName === '') {
            const browser = Bowser.getParser(window.navigator.userAgent)
            const browserObject = browser.getBrowser()
            this.browserName = browserObject.name

            debug.log(this.browserName)
        }

        return (this.browserName === 'Safari')
    }

    initUser(onSuccess): void {
        if (!AppLocalStorage.isSubscriberFingerprintExists()) {
            AuthAPI.create('^&$%35^463ytfuyrd23d')
                .then((response) => {
                    AppLocalStorage.setUserToken(response.data.token)
                    AppLocalStorage.setSubscriberFingerprint(response.data.subscriber_fingerprint)
                    onSuccess()
                })
                .catch((err) => {
                    debug.error('initUser: AuthAPI.create error', err)
                    Events.registerError(
                        UnauthorizedAuthApiError,
                        {
                            point: 'initUser: AuthAPI.create error',
                            err
                        }
                    )
                })
        } else {
            onSuccess()
        }
    }

    // todo extract paymentMethods
    processPayment(paymentSystem: string, paymentToken: string, onSuccess): void {
        if (!AppLocalStorage.isUserTokenExists()) {
            debug.error('processPayment: user token does not exists')

            return
        }

        const userToken = AppLocalStorage.getUserToken()

        const urlTags = UrlTags.getInstance()
        const urlQuery = urlTags.getUrlParams()
        debug.log('processPayment: urlTags.getUrlParams()', urlQuery)

        switch (paymentSystem) {
            case 'ApplePay': {
                SubscriberAPI.createSubscriptionApple(userToken, paymentToken, urlQuery.source, urlQuery.medium, urlQuery.campaign)
                    .then((result) => {
                        debug.log('SubscriberAPI.createSubscription(ApplePay) success', result)
                        onSuccess()
                    })
                    .catch((err) => {
                        debug.error('SubscriberAPI.createSubscription(ApplePay) error', err)
                        Events.registerError(
                            AuthorizedSubscriberApiError,
                            {
                                point: 'SubscriberAPI.createSubscription(ApplePay) error',
                                err
                            }
                        )
                    })

                break
            }
            case 'GooglePay': {
                SubscriberAPI.createSubscriptionGoogle(userToken, paymentToken, urlQuery.source, urlQuery.medium, urlQuery.campaign)
                    .then((result) => {
                        debug.log('SubscriberAPI.createSubscription(GooglePay) success', result)
                        onSuccess()
                    })
                    .catch((err) => {
                        debug.error('SubscriberAPI.createSubscription(GooglePay) error', err)
                        Events.registerError(
                            AuthorizedSubscriberApiError,
                            {
                                point: 'SubscriberAPI.createSubscription(GooglePay) error',
                                err
                            }
                        )
                    })

                break
            }
            default: {
                debug.error('processPayment: unknown paymentSystem', paymentSystem)
                Events.registerError(
                    ScenarioError,
                    {
                        point: 'processPayment: unknown paymentSystem',
                        paymentSystem
                    }
                )

                break
            }
        }
    }

    updateSubscriptionEmail(email: string, onSuccess): void {
        if (!AppLocalStorage.isUserTokenExists()) {
            debug.error('updateSubscriptionEmail: user token does not exists')

            return
        }

        const userToken = AppLocalStorage.getUserToken()

        SubscriberAPI.registerUserEmail(userToken, email)
            .then((result) => {
                if (result.data.success === true) {
                    // Ok - we update email in our DB
                    debug.log('Scenario.updateSubscriptionEmail() registerUserEmail success', result.data)
                    SubscriberAPI.updateSubscriptionEmail(userToken)
                        .then((resultSE) => {
                            debug.log('Scenario.updateSubscriptionEmail() updateSubscriptionEmail success', resultSE.data)
                            onSuccess()
                        })
                        .catch((err) => {
                            debug.error('Scenario.updateSubscriptionEmail() updateSubscriptionEmail error', err)
                            Events.registerError(
                                AuthorizedSubscriberApiError,
                                {
                                    point: 'Scenario.updateSubscriptionEmail() updateSubscriptionEmail error',
                                    err
                                }
                            )
                        })
                } else {
                    debug.error('Scenario.updateSubscriptionEmail() registerUserEmail error', result.data.message)
                    Events.registerError(
                        AuthorizedSubscriberApiError,
                        {
                            point: 'Scenario.updateSubscriptionEmail() registerUserEmail error',
                            result
                        }
                    )
                }
            })
            .catch((err) => {
                debug.error('Scenario.updateSubscriptionEmail() registerUserEmail error', err)
                Events.registerError(
                    AuthorizedSubscriberApiError,
                    {
                        point: 'Scenario.updateSubscriptionEmail() registerUserEmail error',
                        err
                    }
                )
            })
    }

    autoSignIn(): void {
        debug.log('autoSignIn')
        if (AppLocalStorage.isUserEmailExists() && (AppLocalStorage.getRememberMe() === 'yes')) {
            debug.log('autoSignIn: user email exists, rememberMe=yes try to auto login')

            this.signIn(
                AppLocalStorage.getUserEmail(),
                (AppLocalStorage.getRememberMe() === 'yes'),
                () => {
                    if (this.isSubscriptionExists) {
                        this.routeToGame()
                    }
                },
                () => {
                    debug.error('autoSignIn')
                    Events.registerError(
                        ScenarioError,
                        {
                            point: 'autoSignIn'
                        }
                    )
                })
        }
    }

    signIn(email: string, rememberMe: Boolean, onSuccess: any, onError: any): void {
        AuthAPI.login(email, '111')
            .then((response) => {
                debug.log('Scenario.signIn() AuthAPI.login(' + email + ') success', response)
                AppLocalStorage.setUserToken(response.data.token)
                AppLocalStorage.setSubscriberFingerprint(response.data.subscriber_fingerprint)
                AppLocalStorage.setUserEmail(email)
                AppLocalStorage.setRememberMe((rememberMe === true) ? 'yes' : 'no')

                this.checkSubscriptionExists(onSuccess, onError, 'Scenario.signIn()')
            })
            .catch((err) => {
                debug.error('Scenario.signIn() AuthAPI.login(' + email + ') error', err)
                Events.registerError(
                    UnauthorizedAuthApiError,
                    {
                        point: 'Scenario.signIn() AuthAPI.login(' + email + ') error',
                        err,
                        email
                    }
                )
                if (err.response.status === 404) {
                    Events.registerError(
                        ScenarioError,
                        {
                            point: 'Can\'t sign in with this email'
                        }
                    )
                    alert('Can\'t sign in with this email')
                }
            })
    }

    routeToMain(): void {
        debug.log('routeToMain window.location.href = \'/\'')
        window.location.href = '/'
    }

    routeToGame(): void {
        debug.log('routeToGame window.location.href = \'/games/taptap/\'')
        window.location.href = '/games/taptap/'
    }

    getABTest(): void {
        if (ABTestsEnabled) {
            const testIdent = ABTestGameTuningSteps
            if (!AppLocalStorage.isABTestExists(testIdent)) {
                ABTestsAPI.getABTest(testIdent)
                    .then((result) => {
                        AppLocalStorage.setABTest(testIdent, result.data.abTest)
                    })
                    .catch((err) => {
                        debug.error('ABTestsAPI.getABTest() error', err)
                        Events.registerError(
                            UnauthorizedABTestsApiError,
                            {
                                point: 'ABTestsAPI.getABTest() error',
                                err
                            }
                        )
                    })
            }
        }
    }

    checkSubscriptionExists(onSuccess: any, onError: any, caller: string): void {
        debug.log('checkSubscriptionExists: caller ' + caller)

        if (!AppLocalStorage.isUserTokenExists()) {
            debug.error('checkSubscriptionExists: user token does not exists: ' + caller)

            return
        }

        const userToken = AppLocalStorage.getUserToken()

        SubscriberAPI.getUserSubscriptionsInfo(userToken)
            .then((result) => {
                debug.log(caller + ': SubscriberAPI.getUserSubscriptionsInfo() success', result.data)
                if ((result.data.activeSubscriptions !== undefined) && (result.data.activeSubscriptions.length > 0)) {
                    this.isSubscriptionExists = true
                } else {
                    this.isSubscriptionExists = false
                }
                if (onSuccess !== undefined) {
                    onSuccess()
                }
            })
            .catch((err) => {
                debug.error(caller + ': SubscriberAPI.getUserSubscriptionsInfo() error', err)
                Events.registerError(
                    AuthorizedSubscriberApiError,
                    {
                        point: caller + ': SubscriberAPI.getUserSubscriptionsInfo() error',
                        userToken,
                        err
                    }
                )
                if (onError !== undefined) {
                    onError()
                }
            })
    }

    isUserSubscriptionExists(): Boolean {
        return this.isSubscriptionExists
    }

    getSignedEmail(): string {
        return AppLocalStorage.getUserEmail()
    }

    unsubscribeUser(onSuccess): void {
        if (!AppLocalStorage.isUserTokenExists()) {
            debug.error('unsubscribeUser: user token does not exists')

            return
        }

        const userToken = AppLocalStorage.getUserToken()

        SubscriberAPI.unsubscribe(userToken)
            .then((result) => {
                debug.log('unsubscribeUser: SubscriberAPI.unsubscribe() success', result.data)
                if ((result.data.success !== undefined) && (result.data.success === true)) {
                    this.isSubscriptionExists = false
                    alert('Subscription cancelled successful')
                    onSuccess()
                }
            })
            .catch((err) => {
                debug.error('unsubscribeUser: SubscriberAPI.unsubscribe() error', err)
                Events.registerError(
                    AuthorizedSubscriberApiError,
                    {
                        point: 'unsubscribeUser: SubscriberAPI.unsubscribe() error',
                        err
                    }
                )
            })
    }

    startApplePayDialogue(): Boolean {
        const applePayments = ApplePayments.getInstance()

        if (applePayments.isApplePayButtonAvailable() === true) {
            debug.log('applePayments.isApplePayButtonAvailable() === true')
            applePayments.onApplePaymentButtonClicked()

            return true
        }

        debug.error('applePayments.isApplePayButtonAvailable() === false')
        return false
    }

    startGooglePayDialogue(): Boolean {
        const googlePayments = GooglePayments.getInstance()

        if (googlePayments.isGooglePayButtonAvailable() === true) {
            debug.log('googlePayments.isGooglePayButtonAvailable() === true')
            googlePayments.onGooglePaymentButtonClicked()

            return true
        }

        debug.error('googlePayments.isGooglePayButtonAvailable() === false')
        return false
    }

    // тут сделать экстракт метода проверки подписки - что-бы асинхронка была отдельно от клика
    // ** https://developer.squareup.com/forums/t/apple-pay-gives-an-error-invalidaccesserror-must-create-a-new-applepaysession-from-a-user-gesture-handler/5703/8
    // So basically solution
    // is to remove any async call
    // in the click function of Apple Pay button,
    // there should not be any kind of delay
    // between clicking the function and calling token method of apple pay.
    playFreeClick(): void {
        if (this.isSubscriptionExists) {
            // Have Active Subscriptions
            this.routeToGame()
        } else {
            debug.log('playFreeClick: No Subscriptions')
            // this.isBrowserAppleSafari()
            if ((window.ApplePaySession != null) && (window.ApplePaySession.canMakePayments() === true)) {
                debug.log('playFreeClick: No Subscriptions - Safari')
                if (this.startApplePayDialogue() !== true) {
                    this.startGooglePayDialogue()
                }
            } else {
                debug.log('playFreeClick: No Subscriptions - Google')
                this.startGooglePayDialogue()
            }
        }
    }
}
