import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "game-box" }
const _hoisted_2 = { class: "game-statistics-mobile mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_info = _resolveComponent("game-info")!
  const _component_game_statistics = _resolveComponent("game-statistics")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_game_info, { "show-statistics": true }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_game_statistics)
    ])
  ]))
}