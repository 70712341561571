
import SimplePage from '@/app/common/pkg/simplePage/SimplePage.vue'
import {ExternalDataAPI} from '@/app/services/api/UnauthorizedUsers/ExternalDataAPI'

export default {
    name: 'CookiePolicy',
    data() {
        return {
            cookiePolicy: ''
        }
    },
    components: {SimplePage},
    methods: {
        getCookiePolicy() {
            const self = this
            const promiseCookiePolicy = ExternalDataAPI.getCookiePolicy()
            promiseCookiePolicy.then((result) => {
                self.cookiePolicy = result.data.content
            })
        }
    },
    mounted() {
        this.getCookiePolicy()
    }
}
