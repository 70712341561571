import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container mb-3"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col ps-0" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col ps-0 pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.clearStorage && $options.clearStorage(...args)))
            }, "Clear Storage Before Test")
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-outline-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.copyDebugLog && $options.copyDebugLog(...args)))
            }, "Copy Debug Log")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}