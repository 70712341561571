
import {GooglePayments} from '@/app/services/payments/GooglePayments'
import {Events, SubscriptionSuccess, SubscriptionSuccessGoogle} from '@/app/services/Events'

export default {
    name: 'GooglePayButton',
    mounted() {
        this.debug.log('Google Pay Mounted Start')
        // Google Pay
        const googlePayments = GooglePayments.getInstance()
        googlePayments.loadGooglePay('googlePayBtnPlace', this.onSuccessProcessPayment)
        this.debug.log('Google Pay Mounted End')
    },
    methods: {
        onSuccessProcessPayment() {
            setTimeout(() => {
                Events.registerEvent(SubscriptionSuccessGoogle, {
                    click: true
                })
                Events.registerEvent(SubscriptionSuccess, {
                    click: true
                })
            }, 100)
            this.debug.log('onSuccessProcessPayment Google')
            this.$router.push({name: 'update-email'})
            this.debug.log('onSuccessProcessPayment Google after router push')
        }
    }
}
