import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "available-languages-select" }
const _hoisted_2 = { class: "dropdown" }
const _hoisted_3 = {
  class: "btn btn-outline-primary dropdown-toggle w-auto",
  type: "button",
  id: "dropdownMenuButtonAvailableLanguagesSelect",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_4 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuButtonAvailableLanguagesSelect"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, _toDisplayString($options.currentLocaleName), 1),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.locales, (locale) => {
          return (_openBlock(), _createElementBlock("li", {
            key: locale.code
          }, [
            _createElementVNode("span", {
              class: "dropdown-item",
              onClick: ($event: any) => ($options.setLocaleOnClick(locale.code))
            }, _toDisplayString(locale.name), 9, _hoisted_5)
          ]))
        }), 128))
      ])
    ])
  ]))
}