
import TextBox from '@/app/common/pkg/content/pkg/textBox/TextBox.vue'
import MainMenuItems from '@/app/common/pkg/content/pkg/mainMenu/pkg/MenuItems/MainMenuItems.vue'
import CopyRight from '@/app/common/pkg/content/pkg/copyRight/CopyRight.vue'
import AvailableLanguages from '@/app/common/pkg/content/pkg/mainMenu/pkg/AvailableLanguages/AvailableLanguages.vue'
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'

export default {
    name: 'MainMenu',
    components: {RouterLocalizationLink, AvailableLanguages, CopyRight, MainMenuItems, TextBox},
    computed: {
        logoSrcSet() {
            const x2 = require('@/assets/img/logo/logo@2x.webp')
            const x3 = require('@/assets/img/logo/logo@3x.webp')

            return `${x2} 2x, ${x3} 3x`
        }
    }
}
