import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header_mobile = _resolveComponent("page-header-mobile")!
  const _component_page_header_desktop = _resolveComponent("page-header-desktop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_header_mobile, {
      "go-to-back-link": $props.goToBackLink,
      "hide-logo": $props.hideLogo,
      "hide-menu": $props.hideMenu
    }, null, 8, ["go-to-back-link", "hide-logo", "hide-menu"]),
    _createVNode(_component_page_header_desktop)
  ]))
}