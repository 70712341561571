declare const debug: any
declare const window: any

export class UrlTags {
    private static instance: UrlTags
    private source: string = ''
    private medium: string = ''
    private campaign: string = ''

    private constructor() {
    }

    public static getInstance(): UrlTags {
        if (!(UrlTags.instance instanceof UrlTags)) {
            UrlTags.instance = new UrlTags()
        }

        return UrlTags.instance
    }

    readUrlQuery(): void {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        this.source = urlParams.get('utm_source') ?? ''
        this.medium = urlParams.get('utm_medium') ?? ''
        this.campaign = urlParams.get('utm_campaign') ?? ''

        debug.log('getUrlParams', this.getUrlParams())
    }

    getUrlParams(): any {
        return {
            source: this.source,
            medium: this.medium,
            campaign: this.campaign
        }
    }
}
