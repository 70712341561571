
export default {
    name: 'TextBox',
    props: {
        header: {
            type: String,
            required: true
        },
        headerClass: {
            type: String,
            required: false
        }
    }
}
