
import {supportedLocales} from '../../../../../../../../i18n'

export default {
    name: 'AvailableLanguagesSelect',
    methods: {
        setLocaleOnClick(newLocale) {
            if (newLocale === this.$i18n.locale) {
                return
            }

            this.$i18n.locale = newLocale

            this.$router.push({params: {locale: newLocale}})

            const btnCloseMainMenu = document.getElementById('btn-close-main-menu')
            if (btnCloseMainMenu instanceof HTMLElement) {
                btnCloseMainMenu.click()
            }
        }
    },
    computed: {
        currentLocaleName() {
            return supportedLocales[this.$i18n.locale].name
        },
        locales() {
            return Object.keys(supportedLocales).map((code) => ({
                code,
                name: supportedLocales[code].name
            }))
        }
    }
}
