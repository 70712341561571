
import GameStatistics from '@/app/common/pkg/content/pkg/gameBox/pkg/gameStatistics/GameStatistics.vue'

export default {
    name: 'GameInfo',
    components: {GameStatistics},
    props: {
        showStatistics: {
            type: Boolean,
            required: true
        }
    }
}
