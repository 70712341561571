import {createI18n, I18n} from 'vue-i18n'
import {messages} from './messages'
import {numberFormats} from './numbers'

declare const debug: any

// 1. ENGLISH    en
// 2. FRANÇAIS   fr
// 3. DEUTSCH    de
// 4. TÜRKÇE     tr
// 5. POLSKI     pl
// 6. PORTUGUÊS  pt
// 7. 中文        zn
// 8. РУССКИЙ    ru
// 9. ESPAÑOL    es

// Using a { localeCode: localeData } structure
// allows us to add metadata, like a name, to each
// locale as our needs grow.
export const supportedLocales = {
    en: {name: 'ENGLISH'},
    fr: {name: 'FRANÇAIS'},
    de: {name: 'DEUTSCH'},
    tr: {name: 'TÜRKÇE'},
    pl: {name: 'POLSKI'},
    pt: {name: 'PORTUGUÊS'},
    zn: {name: '中文'},
    ru: {name: 'РУССКИЙ'},
    es: {name: 'ESPAÑOL'}
}

const initURLLocale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
debug.log('initURLLocale ', initURLLocale)

// Set and expose the default locale
export const defaultLocale = (initURLLocale === '/')
    ? 'en'
    : ['en', 'fr', 'de', 'tr', 'pl', 'pt', 'zn', 'ru', 'es'].includes(initURLLocale)
        ? initURLLocale
        : 'en'
debug.log('defaultLocale ', defaultLocale)

export const i18n = createI18n({
    // default locale
    locale: defaultLocale,
    // translations
    messages: {
        en: {
            appTitle: 'Hello'
        },
        fr: {
            appTitle: 'Bonjour'
        },
        de: {
            appTitle: 'Bonjour'
        },
        tr: {
            appTitle: 'Bonjour'
        },
        pl: {
            appTitle: 'Bonjour'
        },
        pt: {
            appTitle: 'Bonjour'
        },
        zn: {
            appTitle: 'Bonjour'
        },
        ru: {
            appTitle: 'Bonjour'
        },
        es: {
            appTitle: 'Bonjour'
        }
    }
})

// Private instance of VueI18n object
let _i18n

// Initializer
function setup(options = {locale: defaultLocale}): I18n {
    _i18n = createI18n({
        locale: options.locale,
        fallbackLocale: defaultLocale,
        messages,
        numberFormats
    })

    setLocale(options.locale)

    return _i18n
}

// Sets the active locale.
function setLocale(newLocale): void {
    debug.log(_i18n)
    _i18n.global.locale = newLocale
}

// Public interface
export default {
    // Expose the VueI18n instance via a getter
    get vueI18n() {
        return _i18n
    },
    setup,
    setLocale
}
