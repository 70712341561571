
import MainMenu from '@/app/common/pkg/content/pkg/mainMenu/MainMenu.vue'
import RouterLocalizationLink from '@/app/components/RouterLocalizationLink.vue'

export default {
    name: 'PageHeaderMobile',
    components: {RouterLocalizationLink, MainMenu},
    props: {
        goToBackLink: {
            type: Boolean,
            required: false
        },
        hideLogo: {
            type: Boolean,
            required: false
        },
        hideMenu: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        logoSrcSet() {
            const x2 = require('@/assets/img/logo/logo@2x.webp')
            const x3 = require('@/assets/img/logo/logo@3x.webp')

            return `${x2} 2x, ${x3} 3x`
        },
        visibleBackLink() {
            if (!this.goToBackLink) {
                return 'opacity-0'
            }

            return ''
        }
    }
}
