
import {AppLocalStorage} from '@/app/services/AppLocalStorage'

export default {
    name: 'DebugButtons',
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        clearStorage() {
            AppLocalStorage.clear()
        },
        copyDebugLog() {
            this.debug.copyConsole()
        }
    }
}
