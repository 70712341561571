import {createApp} from 'vue'
import App from './App.vue'
import router from './app/router'
import * as Sentry from '@sentry/vue'
import {i18n} from './i18n'
import {UrlTags} from '@/app/services/UrlTags'
import {Scenario} from '@/app/services/Scenario'
import {Domain} from '@/app/services/Domain'

declare const debug: any
declare const window: any
declare const siteVersion: any
// ---------------------------------------
(BigInt.prototype as any).toJSON = function() {
    return this.toString()
}
// ---------------------------------------
const domain = Domain.getInstance()
// ---------------------------------------
const baseURL = domain.getBaseUrl()
const buildType = process.env.VUE_APP_BUILD_TYPE ?? process.env.NODE_ENV
// ---------------------------------------
const urlTags = UrlTags.getInstance()
urlTags.readUrlQuery()
// ---------------------------------------
// Init User
const scenario = Scenario.getInstance()
scenario.getABTest()
scenario.initUser(() => {
    // check user subscriptions
    scenario.checkSubscriptionExists(
        () => {},
        () => {},
        'main.ts initUser()'
    )
    // Detect Browser
    const isAppleSafari = scenario.isBrowserAppleSafari()
    debug.log('isAppleSafari', isAppleSafari)
    // Init App only after Init User
    // ---------------------------------------
    const app = createApp(App)
    app.config.globalProperties.debug = debug
    app.config.globalProperties.window = window
    app.config.globalProperties.urlTags = urlTags
    app.provide('baseUrl', baseURL)
    app.provide('buildType', buildType)
    // ----------------------------------------
    Sentry.init({
        app,
        dsn: domain.getSentryDsn(),
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: domain.getSentryTracePropagationTargets(),
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
            new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    Sentry.setTag('site-version', siteVersion)
    // ---------------------------------------
    app.use(i18n)
    app.use(router)
    app.mount('#app')
})
