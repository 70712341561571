import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * For everyone Users
 */
export const ApplePaymentsAPI = {
    getPaymentRequestObject: async function() {
        return await BaseAPI.get('/apple-payment/payment-request-object')
    }
}
