import {BaseAPI} from '@/app/services/api/BaseAPI'

/**
 * Only for Authorized Users
 */
export const SubscriberAPI = {
    getUserInfo: async function(authToken: string) {
        return await BaseAPI.get('/user', {
            Authorization: 'Bearer ' + authToken
        })
    },
    getUserSubscriptionsInfo: async function(authToken: string) {
        return await BaseAPI.get('/user/subscriptions-info', {
            Authorization: 'Bearer ' + authToken
        })
    },
    registerUserEmail: async function(authToken: string, email: string) {
        return await BaseAPI.post('/user/register-email',
            {
                email
            },
            {
                Authorization: 'Bearer ' + authToken
            }
        )
    },
    updateSubscriptionEmail: async function(authToken: string) {
        return await BaseAPI.post('/user/update-subscription-email', {}, {
            Authorization: 'Bearer ' + authToken
        })
    },
    createSubscriptionGoogle: async function(authToken: string, token: string, utmSource: string, utmMedium: string, utmCampaign: string) {
        return await BaseAPI.post(
            '/user/create-subscription/google',
            {
                token,
                utmSource,
                utmMedium,
                utmCampaign
            },
            {
                Authorization: 'Bearer ' + authToken
            }
        )
    },
    createSubscriptionApple: async function(authToken: string, token: string, utmSource: string, utmMedium: string, utmCampaign: string) {
        return await BaseAPI.post(
            '/user/create-subscription/apple',
            {
                token,
                utmSource,
                utmMedium,
                utmCampaign
            },
            {
                Authorization: 'Bearer ' + authToken
            }
        )
    },
    unsubscribe: async function(authToken: string) {
        return await BaseAPI.post('/user/unsubscribe', {}, {
            Authorization: 'Bearer ' + authToken
        })
    },
    getStripeCustomerPortalLink: async function(authToken: string) {
        return await BaseAPI.get('/user/customer-portal-link', {
            Authorization: 'Bearer ' + authToken
        })
    }
}
